@font-face {
    font-family: "Flaticon";
    src: url("../css/flaticon/Flaticon.eot");
    src: url("../css/flaticon/Flaticon.eot?#iefix") format("embedded-opentype"),
         url("../css/flaticon/Flaticon.woff") format("woff"),
         url("../css/flaticon/Flaticon.ttf") format("truetype"),
         url("../css/flaticon/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: "Flaticon";
      src: url("../css/flaticon/Flaticon.svg#Flaticon") format("svg");
    }
  }
  
  [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
  [class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: Flaticon;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .flaticon-pawprint-1:before { content: "\f100"; }
  .flaticon-pawprint:before { content: "\f101"; }
  .flaticon-blind:before { content: "\f102"; }
  .flaticon-dog-eating:before { content: "\f103"; }
  .flaticon-grooming:before { content: "\f104"; }
  .flaticon-stethoscope:before { content: "\f105"; }
  .flaticon-customer-service:before { content: "\f106"; }
  .flaticon-emergency-call:before { content: "\f107"; }
  .flaticon-veterinarian:before { content: "\f108"; }
  
  .flaticon-png-icon {
    width: 50px;   /* Adjust dimensions as needed */
    height: 50px;  /* Adjust dimensions as needed */
    background-image: url('../images/dog.png'); /* Adjust the path to your PNG file */
    background-size: cover;
    display: inline-block;   
  }