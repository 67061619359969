/* Spinner.css */

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure spinner is on top of other content */
}

.spinner-container {
  border: 4px solid #f3f3f3; /* Light grey border */
  border-top: 4px solid #3498db; /* Blue border for animation */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Spin animation */
}

.dog-spinner-img {
  width: 100px; /* Adjust size of dog spinner image */
  height: auto; /* Maintain aspect ratio */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

